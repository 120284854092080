import { CONFIG, ENVIRONMENT_TYPE } from '@/config';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const initSentry = () => {
  try {
    if (ENVIRONMENT_TYPE === 'LOCAL_HOST') {
      return;
    }
    Sentry.init({
      dsn: CONFIG.sentryDSN,
      // integrations: [new Integrations.BrowserTracing()],
      environment: ENVIRONMENT_TYPE,
      integrations: [
        // new Sentry.BrowserTracing({
        //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: ENVIRONMENT_TYPE !== 'PROD' ? 0.3 : 0.5,
      replaysSessionSampleRate: ENVIRONMENT_TYPE !== 'PROD' ? 0.1 : 0.3,
      replaysOnErrorSampleRate: 1.0,
    });
  } catch (error) {
    console.log('error in initSentry-->', error);
  }
};

export const setSentryUser = (id: string) => {
  try {
    if (ENVIRONMENT_TYPE !== 'PROD') {
      return;
    }
    Sentry.setUser({ id: id });
  } catch (error) {
    console.log('error in setSentryUser-->', error);
  }
};

export const sendSentryCustomEvent = (eventString: string, tagData: any) => {
  try {
    if (ENVIRONMENT_TYPE !== 'PROD') {
      return;
    }
    Sentry.captureException(eventString, { tags: tagData });
  } catch (error) {
    console.log('error in captureMessage-->', error);
  }
};
