import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ENVIRONMENT_TYPE } from '@/config';
import { fetchTagsInBatch } from '@/fetchers/tag';
import { useGlobalState } from '@/store';
import { updateUserPrefs, useProgramState } from '@/store/program';
import {
  getLanguageDescription,
  hideLoadingOverlay,
  retrieveLanguageSensitiveText,
  showLoadingOverlay,
} from '@/utils';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import ThemeSwitcher from '../ThemeSwitcher';

const DrawerContent = () => {
  const [userId, userPhoto, userEmail, firstName, lastName] = useGlobalState((state) => [
    state.userId,
    state.userPhoto,
    state.userEmail,
    state.firstName,
    state.lastName,
  ]);
  const currentBatchObject = useProgramState((state: any) => state.currentBatchObject);
  const currentRegistration = useProgramState((state) => state.currentRegistration);

  const tags = useProgramState((state) => state.tags);

  const {
    // supportedLanguages = [],
    tagQuestion = [],
  } = currentBatchObject || {};

  const { refetch: refreshTags } = useQuery({
    queryKey: ['tags', currentRegistration?.batchId ?? 'unknown'],
    queryFn: () =>
      currentRegistration?.batchId && fetchTagsInBatch(currentRegistration.batchId),
    refetchOnWindowFocus: false,
    enabled:
      currentRegistration?.batchId !== undefined &&
      currentBatchObject?.areTagsSwitchable === true,
    staleTime: 1000 * 10,
  });

  const {
    register,
    control,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm<{ timezone: string; streamLanguage: string; tag: string }>({
    // resolver: yupResolver(multipleParticipantDetailsSchema),
    defaultValues: {
      timezone: currentRegistration?.timezoneId || '',
      streamLanguage: currentRegistration?.streamLanguage || '',
      tag: currentRegistration?.tag ?? '',
    },
    mode: 'all',
  });
  const currentStreamLanguage = watch('streamLanguage');
  const currentTag = watch('tag');
  const currentTagObject =
    tags?.find((a: ITag) => a.id === currentTag) || currentRegistration?.tagInfo;
  // const supportedLanguages = currentRegistration?.tagInfo?.supportedLanguages || [];
  const supportedLanguages = currentTagObject?.supportedLanguages ?? [];

  useEffect(() => {
    // if (isDrawerOpen) {
    setValue('streamLanguage', currentRegistration?.streamLanguage ?? '');
    setValue('tag', currentRegistration?.tag ?? '');
    if (
      currentRegistration?.batchId !== undefined &&
      currentBatchObject?.areTagsSwitchable === true
    ) {
      refreshTags();
    }
  }, [currentRegistration]);

  const onApplyPreferrences = async () => {
    try {
      showLoadingOverlay();
      if (currentStreamLanguage && currentRegistration && currentTag) {
        if (
          currentStreamLanguage !== currentRegistration.streamLanguage ||
          currentTag !== currentRegistration.tag
        ) {
          await updateUserPrefs(currentStreamLanguage, currentTag);
          reset({
            ...getValues(),
            streamLanguage: currentStreamLanguage,
            tag: currentTag,
          });
        }
      }
    } catch (error: any) {
      console.log('error in onApplyPreferrences-->', error);
      toast.error(error?.response?.data?.message);
    } finally {
      hideLoadingOverlay();
    }
  };

  useEffect(() => {
    // if current stram language is not present in supported languages, then set it to first supported language
    if (currentStreamLanguage && !supportedLanguages.includes(currentStreamLanguage)) {
      setValue('streamLanguage', supportedLanguages[0]);
    }
  }, [currentTag]);

  return (
    <>
      <div className="z-[100] drawer drawer-end">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content" />
        <div className="z-10 drawer-side md:overflow-x-hidden">
          <label
            htmlFor="my-drawer"
            aria-label="close sidebar"
            className="drawer-overlay"
          />
          <div className="min-h-full p-4 overflow-y-auto bg-white menu w-80 text-base-content ">
            <div className="flex flex-row items-center justify-between">
              <label
                htmlFor="my-drawer"
                className="drawer-button btn btn-square btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </label>
              <div
                className="mt-1 text-xl sans-medium text-text"
                data-testid={'settings'}
              >
                Settings
              </div>
              <div />
            </div>

            {userId && (
              <div className="flex flex-row items-center mt-4 mb-6">
                <img
                  className="w-12 h-12 rounded-full"
                  alt="profile"
                  src={userPhoto || ''}
                />
                <div className="flex flex-col justify-center ml-4">
                  <div className="overflow-hidden text-xl sans-medium w-52 whitespace-nowrap text-ellipsis ">
                    {firstName} {lastName}
                  </div>
                  <div className="overflow-hidden text-sm text-gray-500 sans-normal w-52 text-ellipsis whitespace-nowrap">
                    {userEmail}
                  </div>
                </div>
              </div>
            )}

            {ENVIRONMENT_TYPE === 'LOCAL_HOST' && (
              <div className="my-4">
                <ThemeSwitcher />
              </div>
            )}

            {currentBatchObject?.areTagsSwitchable && (
              <div className="flex flex-col mt-6">
                <div className="flex flex-row items-center ">
                  <span className="material-symbols-outlined mr-4 text-gray-500">
                    category
                  </span>
                  <div className="serif-medium text-lg text-gray-500">
                    {retrieveLanguageSensitiveText(tagQuestion)}
                  </div>
                </div>
                <div className={'flex flex-row flex-wrap '}>
                  {tags
                    .filter((x) => x.enabled)
                    .map((a: ITag) => ({ label: a.name, value: a.id }))
                    .map((b: { label: string; value: string }) => (
                      <div
                        className={'flex flex-row items-center mr-10 mt-4'}
                        key={b.value}
                      >
                        <input
                          {...register('tag')}
                          type="radio"
                          name={'tag'}
                          value={b.value}
                          id={`rad-${b.value}`}
                        />
                        <label
                          htmlFor={`rad-${b.value}`}
                          className={clsx(
                            'ml-5 text-gray-500 text-base sans-normal',
                            b.value !== 'en' && 'font-semibold',
                          )}
                        >
                          {b.label}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {(supportedLanguages?.length ?? 0) > 0 && (
              <div className="flex flex-col mt-6">
                <div className="flex flex-row items-center ">
                  <span className="material-symbols-outlined mr-4 text-gray-500">
                    translate
                  </span>
                  <div className="serif-medium text-lg text-gray-500">Language</div>
                </div>

                <div className={'flex flex-row flex-wrap '}>
                  {supportedLanguages
                    .map((a: string) => ({ label: getLanguageDescription(a), value: a }))
                    .map((a: any) => (
                      <div
                        className={'flex flex-row items-center mr-10 mt-4'}
                        key={a.value}
                      >
                        <input
                          {...register('streamLanguage')}
                          type="radio"
                          name={'streamLanguage'}
                          value={a.value}
                          id={`rad-${a.value}`}
                          className="radio radio-primary"
                        />
                        <label
                          htmlFor={`rad-${a.value}`}
                          className={clsx(
                            'ml-5 text-gray-500 text-base sans-normal',
                            a.value !== 'en' && 'font-semibold',
                          )}
                        >
                          {a.label}
                        </label>
                      </div>
                    ))}
                </div>
                {errors.streamLanguage?.message && (
                  <p className=" text-sm text-red-500 mt-1">
                    {errors.streamLanguage?.message}
                  </p>
                )}
              </div>
            )}

            {currentRegistration && (
              <button
                disabled={!isDirty}
                onClick={onApplyPreferrences}
                className="mt-6 btn btn-primary btn-block text-white uppercase"
              >
                Apply
              </button>
            )}

            {userId && (
              <button
                className="mt-auto btn btn-primary btn-link btn-block text-lg"
                onClick={() => window.location.replace('/logout')}
              >
                Log out
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Drawer = () => {
  const currentPage = useProgramState((state) => state.currentPage);
  const isInPageMode = !!currentPage;
  const location = useLocation();
  const isInZoomLobby = location.pathname.toLowerCase().includes('zoomlobby');

  if (isInPageMode || isInZoomLobby) {
    return null;
  } else {
    return <DrawerContent />;
  }
};

export default Drawer;
