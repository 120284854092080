import './styles/tailwind.css';

import ReactDOM from 'react-dom/client';

import App from './App';
import { ENVIRONMENT_TYPE } from './config';

if (ENVIRONMENT_TYPE === 'PROD') {
  if (localStorage.getItem('enableLogs') !== '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
);
