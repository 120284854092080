import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import AppRouter from './AppRouter';
import EMQXStatusBar from './components/EMQXStatusBar';
import FullScreenLoading from './components/FullScreenLoading';
import TimeKeeperUi from './components/TimeKeeperUI';
import { GLOBAL_QUERY_CLIENT } from './constants';
import { useGlobalState } from './store';
import { initCountryData, initLanguages } from './utils/configMaps';
import { closeMqtt, unSubscribeToProgramBroadcast } from './utils/events';
import { initSentry } from './utils/sentry';
import { seedServerTime } from './utils/timeKeeper';

function App() {
  const [isReady, setIsReady] = useState<boolean>(false);
  const userId = useGlobalState((state) => state.userId);

  useEffect(() => {
    const init = async () => {
      try {
        initSentry();
        unSubscribeToProgramBroadcast();
        await Promise.all([seedServerTime(), initLanguages(), initCountryData()]);
        setIsReady(true);
      } catch (error) {
        console.log('error in init-->', error);
      }
    };
    init();
    return () => {
      closeMqtt();
    };
  }, []);

  if (!isReady) {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center">
        <span className="loading loading-infinity loading-lg text-primary"></span>
      </div>
    );
  }

  return (
    <>
      {userId && <EMQXStatusBar />}
      <TimeKeeperUi />
      <QueryClientProvider client={GLOBAL_QUERY_CLIENT}>
        <FullScreenLoading />
        <Toaster position="top-center" reverseOrder={false} />
        <AppRouter />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
export default App;
