import axios from 'axios';

/*
1. https://gprs-config.netlify.app
2. https://ipd-cdn.sadhguru.org/masters
*/

const BASE_DISTRIBUTION_URL = 'https://ipd-cdn.sadhguru.org/masters';

export let MATERIAL_ICON_LIST: string[] = [];

export let TIMEZONES: {
  value: string;
  abbr: string;
  offset: number;
  isdst: boolean;
  text: string;
  utc: string;
}[] = [];

export let LANGUAGES: { code: string; name: string; nativeName: string }[] = [];
export let COUNTRY_DATA: [
  { countryName: string; countryCode: string; dialCode: string; region: string },
];
export let REGION_CENTER_MAP: {
  region: string;
  centers: string[];
}[];
export let INDIA_REGION_CENTER_MAP: { center: string; region: string }[];

export const initCountryData = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/country-data.json`);
    COUNTRY_DATA = resp.data;
  } catch (error) {
    console.log('error in initCountryData-->', error);
    // throw error;
  }
};

export const initRegionCenterMap = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/region-center-map.json`);
    REGION_CENTER_MAP = resp.data;
  } catch (error) {
    console.log('error in initRegionCenterMap-->', error);
    throw error;
  }
};

export const initIndiaRegionCenterMap = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/in-cen-reg-map.json`);
    INDIA_REGION_CENTER_MAP = resp.data;
  } catch (error) {
    console.log('error in initIndiaRegionCenterMap-->', error);
    throw error;
  }
};

export const initLanguages = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/languages.json`);
    LANGUAGES = resp.data;
  } catch (error) {
    console.log('error in initLanguages-->', error);
    // throw error;
  }
};

export const initTimezones = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/timezones.json`);
    TIMEZONES = resp.data.sort((a: any, b: any) => a.offset - b.offset);
  } catch (error) {
    console.log('error in initTimezones-->', error);
    throw error;
  }
};

export const initMaterialIcons = async () => {
  try {
    const resp = await axios.get(`${BASE_DISTRIBUTION_URL}/material-icons.json`);
    MATERIAL_ICON_LIST = resp.data;
  } catch (error) {
    console.log('error in initMaterialIcons-->', error);
    throw error;
  }
};
