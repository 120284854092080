import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface IProps {
  text: string;
  textClasses?: string;
  widthInPixels?: number;
}

const MarqueeText = (props: IProps) => {
  const parentRef = useRef<any>(null);
  const { text, textClasses = '', widthInPixels = 240 } = props;
  useEffect(() => {
    if (parentRef.current) {
      if (parentRef.current.scrollWidth > parentRef.current.clientWidth) {
        parentRef.current.classList.add('marquee');
      }
    }
  }, []);

  return (
    <div
      ref={parentRef}
      className={clsx(
        `w-[${widthInPixels}px] overflow-hidden whitespace-nowrap flex flex-col items-center`,
      )}
    >
      <p className={clsx(textClasses)}>{text}</p>
    </div>
  );
};

export default MarqueeText;
