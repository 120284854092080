import { AxiosRequestConfig } from 'axios';
import jsonLogic from 'json-logic-js';
import toast from 'react-hot-toast';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { SESSION_MODE } from '@/constants';
import { retrieveLanguageSensitiveText, sleep } from '@/utils';
import { axiosBridged } from '@/utils/network';
import parseJsonLogic from '@/utils/parseJsonLogic';

import { getAccessorObject, getSSOId } from '.';

interface IGlobalProgramState {
  currentProgram: IProgram | null;
  currentSteps: IStep[];
  currentStep: IStep | null;
  currentRegistration: IRegistration | null;
  currentTag: ITag | null;
  currentSession: ISession | null;
  currentStepEvents: IEvent[];
  currentBatchObject: IBatch | null;
  currentSessionMode: SESSION_MODE | null;
  tags: ITag[];
  currentPage: IPage | null;
  selectedPageLanguage?: string;
}

const INITIAL_STATE = {
  currentProgram: null,
  currentSteps: [],
  currentStepEvents: [],
  currentTag: null,
  currentStep: null,
  currentRegistration: null,
  currentSession: null,
  currentBatchObject: null,
  currentSessionMode: null,
  tags: [],
  currentPage: null,
  selectedPageLanguage: undefined,
};

const useProgramStore = create<IGlobalProgramState>()(
  devtools(
    persist(
      (set, get) => ({
        ...INITIAL_STATE,
      }),
      {
        name: 'ipd-user-pgm',
      },
    ),
  ),
);

export const useProgramState = useProgramStore;

export const getCurrentRegistration = () => {
  return useProgramStore.getState().currentRegistration;
};

export const getRegistrationStreamLanguage = () => {
  const { currentRegistration, selectedPageLanguage } = useProgramStore.getState();
  return currentRegistration?.streamLanguage ?? (selectedPageLanguage || 'en');
};

export const getCurrentStepAndSession = (): any => {
  try {
    const { currentStep, currentSession } = useProgramStore.getState();
    return { currentStep, currentSession };
  } catch (error) {
    console.log('error in getCurrentStepAndSession-->', error);
    return {};
  }
};

export const resetProgramState = () => {
  try {
    useProgramStore.setState({ ...INITIAL_STATE });
  } catch (error) {
    console.log('error in resetGlobalState-->', error);
  }
};

export const setProgramState = (data: IGlobalProgramState | Record<string, unknown>) => {
  try {
    useProgramStore.setState({
      ...useProgramStore.getState(),
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getProgramAndRegistration = () => {
  const { currentProgram, currentRegistration } = useProgramStore.getState();
  return { currentProgram, currentRegistration };
};

const extractEcgCondition = (data: any, elementId: string): any => {
  try {
    if (data.rules) {
      for (const x of data.rules) {
        if (x.field === elementId) {
          return x.value;
        } else {
          if (x?.rules) {
            return extractEcgCondition(x, elementId);
          }
        }
      }
    }
    return null;
  } catch (error) {
    console.log('error in extraction of ecg condition', error);
    throw error;
  }
};

const prepareElementEventsForJsonLogic = (
  events: IEvent[],
  rulesInEligibilityCriteria: any,
) => {
  try {
    const finalData: any = {};
    console.log(events, rulesInEligibilityCriteria);
    for (const ev of events) {
      const { type, ecg = '', elementId, data } = ev;
      switch (type) {
        case 'ECG_UPDATE': {
          const targetValue = extractEcgCondition(
            parseJsonLogic(rulesInEligibilityCriteria, {}),
            elementId,
          );
          console.log('ECG: TARGET VALUE', targetValue);
          if (targetValue) {
            const targetSplits = targetValue.split(',').map((a: string) => a.trim());
            if (targetSplits.length !== 3) {
              throw new Error('Invalid target value');
            }
            const [startIndex, endIndex, requiredLength] = targetSplits;
            const apparentECG = ecg + '0'.repeat(Math.abs(ecg.length - endIndex));
            const targetLength = apparentECG
              .slice(startIndex, endIndex)
              .replace(/0/g, '').length;
            finalData[elementId] = `${startIndex},${endIndex},${
              targetLength >= requiredLength ? requiredLength : -1
            }`;
          }

          break;
        }
        case 'VOD_PLAY_HEAD_UPDATE': {
          finalData[elementId] = data?.currentPercentage ?? 0;
          break;
        }
        case 'STEP_IN': {
          finalData[elementId] = true;
          break;
        }
        case 'SESSION_IN': {
          finalData[elementId] = true;
          break;
        }
        default:
          break;
      }
    }
    return finalData;
  } catch (error) {
    console.log('error in prepareElementEventsForJsonLogic-->', error);
  }
};

export const canGoToNextStepOrSession = async (currentEntity: IStep | ISession) => {
  try {
    if (!currentEntity?.eligibilityCriteria) {
      return true;
    }
    // const { userId } = useGlobalState.getState();
    // console.log("ENTITY ", currentEntity)
    // console.log("ELIGILIBILITY ", currentEntity.eligibilityCriteria)
    const elementsInEligibilityCriteria =
      currentEntity?.eligibilityCriteria?.elementsUsed ?? [];
    const rulesInEligibilityCriteria = currentEntity?.eligibilityCriteria?.rules;
    // console.log("elements in criteria-->", elementsInEligibilityCriteria);
    // console.log("rules in criteria-->", rulesInEligibilityCriteria);
    if (rulesInEligibilityCriteria === undefined) {
      return true;
    }
    if (elementsInEligibilityCriteria.length === 0) {
      return true;
    }
    const resp: IAPIResp = await axiosBridged.get('/events/byTargets', {
      params: {
        targets: elementsInEligibilityCriteria.join(','),
        // ssoId: userId
        ...getAccessorObject(),
      },
    });
    const eventLogicData = prepareElementEventsForJsonLogic(
      resp.payload,
      rulesInEligibilityCriteria,
    );
    // console.log('EVENT LOGIC FATA ', eventLogicData);
    const jsonLogicResult = jsonLogic.apply(rulesInEligibilityCriteria, eventLogicData);
    // console.log("JSON LOGIC RESULT ", jsonLogicResult)
    return jsonLogicResult;
  } catch (error) {
    console.log('error in canGoToNextStep-->', error);
    throw error;
  }
};

export const goToNextStep = async () => {
  try {
    const { currentStep, currentProgram, currentRegistration } =
      useProgramStore.getState();
    if (currentStep) {
      const canGo = await canGoToNextStepOrSession(currentStep);

      if (canGo) {
        const { nextStep } = currentStep || {};
        if (nextStep && currentProgram) {
          const regUpdateResp: IAPIResp = await axiosBridged.put(
            '/registrations/currentStep',
            {
              // ssoId: getSSOId(),
              ...getAccessorObject(),
              regId: currentRegistration?.id,
              programId: currentProgram.id,
              nextStep,
            },
          );
          if (!regUpdateResp?.payload) {
            console.log('error in updating step', regUpdateResp);
            return;
          }
          const stepResp: IAPIResp = await axiosBridged.get('/steps', {
            params: {
              id: nextStep,
            },
          });
          setProgramState({
            currentRegistration: { ...currentRegistration, ...regUpdateResp?.payload },
            currentStep: stepResp?.payload,
          });
          await refreshStepEvents();
        }
      } else {
        toast.error(
          retrieveLanguageSensitiveText(currentStep?.meta?.IneligibilityText) ||
            'You have missed essential parts of the session. Please contact the support team for further steps.',
        );
      }
    }
  } catch (error) {
    console.log('error in goToNextStep-->', error);
  }
};

export const goToTargetStepIfCompleted = async (targetStepId: string) => {
  try {
    const { currentProgram, currentStepEvents, currentRegistration } =
      useProgramStore.getState();
    if (!currentProgram) {
      toast.error("can't find current program");
      return;
    }
    if (!currentRegistration) {
      toast.error("can't find current registration");
      return;
    }
    const isTargetStepCompleted =
      currentStepEvents.find((a: IEvent) => a.elementId == targetStepId)?.inTime !==
      undefined;
    if (isTargetStepCompleted) {
      const regUpdateResp: IAPIResp = await axiosBridged.put(
        '/registrations/currentStep',
        {
          ...getAccessorObject(),
          programId: currentProgram.id,
          regId: currentRegistration.id,
          nextStep: targetStepId,
        },
      );
      if (!regUpdateResp?.payload) {
        console.log('error in updating step', regUpdateResp);
        return;
      }
      const stepResp: IAPIResp = await axiosBridged.get('/steps', {
        params: {
          id: targetStepId,
        },
      });
      console.log('setting ', { ...currentRegistration, ...regUpdateResp?.payload });
      setProgramState({
        currentRegistration: { ...currentRegistration, ...regUpdateResp?.payload },
        currentStep: stepResp?.payload,
      });
    } else {
      alert('Target step not completed');
    }
  } catch (error) {
    console.log('error in goToTargetStepIfCompleted-->', error);
    throw error;
  }
};

export const getEventPrelude = (): {
  programId: string;
  registrationId: string;
  timezoneId: string;
  batchId: string;
  tag: string | null;
  streamLanguage: string;
} => {
  try {
    const { currentRegistration, currentProgram } = useProgramStore.getState();
    if (!currentRegistration || !currentProgram) {
      throw new Error(
        'currentRegistration or currentProgram not found for event prelude',
      );
    }

    const resp = {
      programId: currentProgram?.id,
      registrationId: currentRegistration?.id,
      timezoneId: currentRegistration?.timezoneId,
      batchId: currentRegistration?.batchId,
      tag: currentRegistration.tag,
      streamLanguage: currentRegistration.streamLanguage,
    };

    if (
      !resp.programId ||
      !resp.registrationId ||
      !resp.timezoneId ||
      !resp.batchId ||
      !resp.streamLanguage
    ) {
      throw new Error('programId not found for event prelude');
    }

    return resp;
  } catch (error) {
    console.log('error in getEventPrelude-->', error);
    throw error;
  }
};

export const refreshStepEvents = async (): Promise<IEvent[]> => {
  try {
    const { currentSteps } = useProgramStore.getState();
    const stepIds = currentSteps.map((a: IStep) => a.id);
    if (stepIds.length === 0) {
      return [];
      // throw new Error("refreshStepEvents: step id list is empty")
    }
    await sleep(500);
    const resp: IAPIResp = await axiosBridged.get('/events/byTargets', {
      params: {
        targets: stepIds.join(','),
        ...getAccessorObject(),
      },
      // noLoadingUI: true,
    } as AxiosRequestConfig<any>);
    setProgramState({
      currentStepEvents: resp?.payload,
    });

    return resp?.payload;
  } catch (error) {
    console.log('error in refreshStepEvents-->', error);
    throw error;
  }
};

export const fetchRegistrations = async (): Promise<IRegistration[]> => {
  try {
    const userId = getSSOId();
    if (!userId) {
      throw new Error('sso id not found');
    }
    const resp: IAPIResp = await axiosBridged.get('/registrations/list', {
      params: { ssoId: userId, isBrowser: true },
    });
    // setRegistrations(resp.payload)
    return resp.payload;
  } catch (error) {
    console.log('error in fetchRegistrations-->', error);
    throw error;
  }
};

export const fetchStepsInProgram = async (): Promise<IStep[]> => {
  try {
    const { currentRegistration } = useProgramStore.getState();
    if (!currentRegistration) {
      throw new Error('current registration not found');
    }
    const stepsResp: IAPIResp = await axiosBridged.get('/steps/list', {
      params: {
        programId: currentRegistration?.programId,
        timezoneId: currentRegistration?.timezoneId,
      },
    });
    setProgramState({
      currentSteps: stepsResp.payload,
      currentStep: stepsResp.payload.find(
        (a: IStep) => a.id === currentRegistration?.currentStep,
      ),
    });
    return stepsResp.payload as IStep[];
  } catch (error) {
    console.log('error in fetchStepsInProgram-->', error);
    throw error;
  }
};

export const fetchSessionData = async (id: string): Promise<ISession> => {
  try {
    const { currentSession } = useProgramStore.getState();
    const resp: IAPIResp = await axiosBridged.get('/sessions', {
      params: {
        id,
      },
    });
    if (currentSession) {
      if (currentSession.id === id) {
        if (resp.payload) {
          setProgramState({
            currentSession: resp.payload,
          });
        }
      }
    }
    return resp.payload;
  } catch (error) {
    console.log('error in fetchSession-->', error);
    throw error;
  }
};

export const fetchElementData = async (id: string): Promise<IElement> => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/elements', {
      params: {
        id,
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchElementData-->', error);
    throw error;
  }
};

// export const fetchTimezonesInCurrentBatch = async () => {
//   try {
//     const { currentRegistration } = useProgramStore.getState();
//     if (!currentRegistration) {
//       throw new Error('current registration not found');
//     }
//     const resp: IAPIResp = await axiosBridged.get('timezones/list', {
//       params: {
//         batchId: currentRegistration.batchId,
//       },
//     });
//     setProgramState({
//       currentTimezones: resp.payload,
//     });
//     return resp.payload;
//   } catch (error) {
//     console.log('error in fetchTimezonesInCurrentBatch-->', error);
//     throw error;
//   }
// };

export const fetchRegistrationByIds = async (
  programId: string,
  batchId: string,
  timezoneId: string,
  ssoId?: string,
  email?: string,
) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/registrations/targeted', {
      params: {
        programId,
        batchId,
        timezoneId,
        ssoId,
        email,
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchRegistrationByIds-->', error);
    return null;
  }
};
export const fetchRegistrationByProgramId = async (data: {
  programId: string;
  ssoId?: string;
  email?: string;
  accessToken?: string;
}) => {
  try {
    const { programId, ssoId, email, accessToken } = data;
    const resp: IAPIResp = await axiosBridged.get('/registrations/pgmId', {
      params: {
        programId,
        ssoId,
        email,
        accessToken,
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchRegistrationByProgramId-->', error);
    return null;
  }
};

export const getTimezonesForBatch = async (batchId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('timezones/list', {
      params: {
        batchId: batchId,
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in getTimezonesForBatch-->', error);
    throw error;
  }
};

export const getEventByTargets = async (targets: string[]) => {
  try {
    if (targets.length === 0) {
      return [];
    }
    const resp: IAPIResp = await axiosBridged.get('/events/byTargets', {
      params: {
        targets: targets.join(','),
        ...getAccessorObject(),
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in getEventByTargets-->', error);
    throw error;
  }
};

export const fetchBatchById = async (batchId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/batches/', {
      params: {
        id: batchId,
      },
    });
    resp.payload &&
      setProgramState({
        currentBatchObject: resp.payload,
      });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchBatchById-->', error);
    throw error;
  }
};

export const fetchTagById = async (id: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get(`/tags/${encodeURIComponent(id)}`);
    return resp.payload;
  } catch (error) {
    console.log('error in fetchTagById-->', error);
    throw error;
  }
};

export const updateStreamLanguage = async (lang: string) => {
  try {
    const { currentRegistration } = useProgramStore.getState();
    if (currentRegistration) {
      await axiosBridged.put('/registrations/streamLanguage', {
        regId: currentRegistration.id,
        streamLanguage: lang,
      });
      setProgramState({
        currentRegistration: {
          ...currentRegistration,
          streamLanguage: lang,
        },
      });
    }
  } catch (error) {
    console.log('error in updateStreamLanguage-->', error);
    throw error;
  }
};

export const updateUserPrefs = async (lang: string, tag: string) => {
  try {
    const { currentRegistration } = useProgramStore.getState();
    if (currentRegistration) {
      const resp: IAPIResp = await axiosBridged.put('/registrations/prefs', {
        regId: currentRegistration.id,
        streamLanguage: lang,
        tag,
      });

      setProgramState({
        currentRegistration: {
          ...currentRegistration,
          ...resp.payload,
        },
      });
    }
  } catch (error) {
    console.log('error in updateUserPrefs-->', error);
    throw error;
  }
};

export const fetchAllAccessibleLearningCenters = async (
  limit = 10,
  offset = 0,
): Promise<IRegistration[]> => {
  try {
    const userId = getSSOId();
    const resp: IAPIResp = await axiosBridged.get('/learningCenter', {
      params: {
        accessorId: userId,
        limit,
        offset,
      },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchAllAccessibleLearningCenters-->', error);
    throw error;
  }
};
