import axios from 'axios';
import { clearInterval, setInterval } from 'worker-timers';

let SERVER_TIME: Date | null = null;

let TICKS_DONE = 0;
const TIMER_RESYNC_FREQUENCY = 30 * 60;

let timeKeeperIntervalRef: any = null;

export const getServerTime = (): Date => {
  try {
    if (!SERVER_TIME) {
      throw new Error('App clock error');
    }
    return SERVER_TIME;
  } catch (error) {
    console.log('error in getServerTime-->', error);
    throw error;
  }
};

export const seedServerTime = async () => {
  try {
    const resp = await axios.get('https://time.sadhguru.org');
    SERVER_TIME = new Date(resp.data);
    startTimeKeeper();
  } catch (error) {
    console.log('error in seedServerTime-->', error);
    throw error;
  }
};

const tick = () => {
  try {
    if (!SERVER_TIME) {
      throw new Error('App clock error');
    }
    SERVER_TIME = new Date(SERVER_TIME.getTime() + 1000);
    TICKS_DONE++;
    const targetText = document.getElementById('timeKeeper-text');
    if (targetText) {
      targetText.innerHTML = SERVER_TIME.toLocaleString();
    }
    if (TICKS_DONE % TIMER_RESYNC_FREQUENCY === 0 && TICKS_DONE !== 0) {
      resyncTime();
    }
  } catch (error) {
    console.log('error in tick-->', error);
  }
};

const resyncTime = () => {
  try {
    console.log('Re syncing timer');
    timeKeeperIntervalRef && clearInterval(timeKeeperIntervalRef);
    timeKeeperIntervalRef = null;
    seedServerTime();
  } catch (error) {
    console.log('error in resyncTime-->', error);
    startTimeKeeper();
  }
};

const startTimeKeeper = () => {
  try {
    timeKeeperIntervalRef && clearInterval(timeKeeperIntervalRef);
    timeKeeperIntervalRef = null;
    timeKeeperIntervalRef = setInterval(tick, 1000);
  } catch (error) {
    console.log('error in startTimeKeeper-->', error);
  }
};
