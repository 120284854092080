import axios from 'axios';
import { format } from 'date-fns';
import { isMobile } from 'react-device-detect';
import toast from 'react-hot-toast';

import { TABLE_ENTRY_TYPE } from '@/constants';
import { setGlobalState } from '@/store';
import { getEventByTargets, getRegistrationStreamLanguage } from '@/store/program';

import { LANGUAGES } from './configMaps';
import { axiosBridged } from './network';
import { getServerTime } from './timeKeeper';

export const getFaviconPath = (entity: string) => {
  try {
    switch (entity) {
      case 'IF':
        return '../assets/favicons/IF.png';
      case 'SYT':
        return '../assets/favicons/SYT.png';

      default:
        return '../assets/favicons/IF.png';
    }
  } catch (error) {
    console.log('error in getFavicon-->', error);
  }
};

export const getSSOData = async (userId: string) => {
  try {
    const resp: {
      payload: {
        countryOfResidence: string;
        email: string;
        firstName: string;
        gender: string;
        lastName: string;
        phone: any;
        whatsapp: any;
      };
    } = await axiosBridged.get(`/sso/basicProfile?userId=${userId}`);
    return resp.payload;
  } catch (error) {
    console.log('error in getSSOData-->', error);
    throw error;
  }
};

export function setWithExpiry(key: string, value: any, ttl: any) {
  const item = {
    value: value,
    expiry: getServerTime().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = getServerTime().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export const getDetailsOfPinCode = async (countryCode: any, pinCode: any) => {
  try {
    const resp = await axios.get(
      `https://cdi-gateway.isha.in/contactinfovalidation/api/countries/${countryCode}/pincodes/${pinCode}`,
    );
    return resp.data;
  } catch (error) {
    console.log('error in getDetailsOfPinCode-->', error);
    throw error;
  }
};

// TODO rename
export const getPartialUserProfile = async (email: string) => {
  try {
    const profileResponse: IAPIResp = await axiosBridged.get(
      `/sso/profileCheck?email=${email}`,
    );
    if (profileResponse.payload !== null) {
      const basicProfileResponse: IAPIResp = await axiosBridged.get(
        `/sso/basicProfile?userId=${profileResponse.payload.profileId}`,
      );
      return {
        ...profileResponse.payload,
        ...basicProfileResponse.payload,
      };
    } else {
      return profileResponse.payload;
    }
  } catch (error) {
    console.log('error in getPartialUserProfile-->', error);
    throw error;
  }
};

/**
 *
 * @param email
 * @returns sso profile if exists, null otherwise
 * @summary
 * 1.checks if sso profile exists, response can be either null or
    "profileId": string,
    "email": string,
    "phone": null,
    "enabled": boolean
    "phoneVerified": any
    "emailVerified": boolean
 * 2. if it's not null, we'll get basicProfile information, and return it by merging both the responses(profileCheck,basicProfile)
      1. We can get the profile data
      2. It can be undefined too
 */

export const getSsoProfileOfUserByEmail = async (email: string) => {
  try {
    const profileResponse: IAPIResp = await axiosBridged.get(
      `/sso/profileCheck?email=${email}`,
    );
    if (profileResponse.payload !== null) {
      const basicProfileResponse: IAPIResp = await axiosBridged.get(
        `/sso/basicProfile?userId=${profileResponse.payload.profileId}`,
      );
      return {
        ...profileResponse.payload,
        ...basicProfileResponse.payload,
      };
    } else {
      return profileResponse.payload;
    }
  } catch (error) {
    console.log('error in getSsoProfileOfUserByEmail -->', error);
    throw error;
  }
};

/**
 *
 * @param email
 * @returns sso profile with (profileId) if exists, null otherwise
 */

export const getSsoProfileByEmail = async (email: string) => {
  try {
    const profileResponse: IAPIResp = await axiosBridged.get(
      `/sso/profileCheck?email=${email}`,
    );
    return profileResponse.payload;
  } catch (error) {
    console.log('error in getSsoProfileByEmail -->', error);
    throw error;
  }
};

export const getSsoProfileBySsoId = async (profileId: string) => {
  try {
    const ssoProfileResponse: IAPIResp = await axiosBridged.get(
      `/sso/basicProfile?userId=${profileId}`,
    );
    return ssoProfileResponse.payload;
  } catch (error) {
    console.log('error in getSsoProfileBySsoId-->', error);
    throw error;
  }
};

export const getSSOEntitlements = async (userId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/sso/entitlements', {
      params: { userId },
    });
    return resp.payload;
  } catch (error) {
    console.log('error in getSSOEntitlements-->', error);
    return [];
  }
};

// DOM utils

/**
 *
 * @param id
 * @see https://encodingcompiler.com/community/391/javascript-scrollintoview-smooth-scroll-and-offset
 */

const SCROLL_TOP_OFFSET = 200;

export const scrollToNodeWithId = (id: string) => {
  try {
    const target = document.getElementById(id);
    if (target) {
      const headerOffset = SCROLL_TOP_OFFSET;
      const elementPosition = target.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
      // target.scrollIntoView({ behavior: 'smooth' });
    }
  } catch (error) {
    console.log('error in scrollToNodeWithId-->', error);
  }
};

export const showLoadingOverlay = () => {
  const target = document.getElementById('full-screen-loader');
  if (target) {
    target.classList.remove('hidden');
  }
};

export const hideLoadingOverlay = () => {
  const target = document.getElementById('full-screen-loader');
  if (target) {
    target.classList.add('hidden');
  }
};

/**
 * @param str
 * @returns Start case string
 *  1. _ replaced by empty spaces and first letter capitalized
 * @example RITUALS_OFFERINGS as Rituals Offerings
 */

export const toStartCase = (str: string) => {
  try {
    return str
      .split('_')
      .join(' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
      .join(' ');
  } catch (error) {
    console.log('error in toTitleCase-->', error);
  }
};

export const isElementASession = (elId: string) => {
  return elId.slice(0, 4) === 'ses_';
};

export const lockToPortrait = () => {
  try {
    isMobile &&
      (window.screen.orientation as any)
        ?.lock('portrait')
        .then((ev: any) => console.log(ev))
        .catch((err: any) => console.log(err));
  } catch (error) {
    console.log('error in lockToPortrait-->', error);
  }
};

export const lockToLandscape = () => {
  try {
    isMobile &&
      (window.screen.orientation as any)
        ?.lock('landscape')
        .then((ev: any) => console.log(ev))
        .catch((err: any) => console.log(err));
  } catch (error) {
    console.log('error in lockToLandscape-->', error);
  }
};

export const noop = () => {
  console.log('noop func');
};

export const retrieveLanguageSensitiveText = (
  data: { lang: string; text: string }[],
  forceLang?: string,
): string => {
  try {
    if (!data) {
      return '';
    }
    const userStreamLanguage = getRegistrationStreamLanguage();
    const targetLang = data.find(
      (a) => a.lang === (forceLang ? forceLang : userStreamLanguage),
    );
    if (!targetLang) {
      const englishLang = data.find((a) => a.lang === 'en');
      return englishLang ? englishLang.text : '';
    }
    return targetLang?.text || '';
  } catch (error) {
    console.log('error in retrieveLanguageSensitiveText-->', error);
    return '';
  }
};

export const retrieveLanguageSensitiveVideoSource = (
  data: IVideoSource[],
  lang?: string,
) => {
  try {
    if (!data) {
      return '';
    }
    const userStreamLanguage = lang || getRegistrationStreamLanguage();
    return (
      data.find((vs) => vs.lang === userStreamLanguage) ||
      data.find((vs) => vs.lang === 'en')
    );
  } catch (error) {
    console.log('error in retrieveLanguageSensitiveVideoSource-->', error);
  }
};

export const retrieveLanguageSensitiveTableSchema = (
  data: ITableConfig,
  lang?: string,
) => {
  try {
    if (!data) {
      return '';
    }
    const userStreamLanguage = lang || getRegistrationStreamLanguage();
    return (
      data.tables.find((vs) => vs.lang === userStreamLanguage) ||
      data.tables.find((vs) => vs.lang === 'en')
    )?.schema;
  } catch (error) {
    console.log('error in retrieveLanguageSensitiveTableSchema-->', error);
  }
};

export const extractYoutubeId = (url: string) => {
  const urlSplits = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return urlSplits[2] !== undefined
    ? urlSplits[2].split(/[^0-9a-z_-]/i)[0]
    : urlSplits[0];
};

export const getOrderedStepIds = (steps: IStep[]) => {
  try {
    const stepOrderIds: string[] = [];
    const firstStep = steps.find((a) => a.isFirst);
    if (firstStep) {
      stepOrderIds.push(firstStep.id);
      let nextStepObj = steps.find((a) => a.id === firstStep.nextStep);
      while (nextStepObj !== undefined) {
        stepOrderIds.push(nextStepObj.id);
        nextStepObj = steps.find((a) => a.id === nextStepObj?.nextStep);
        if (nextStepObj) {
          if (stepOrderIds.includes(nextStepObj.id)) {
            break;
          }
        }
      }
      return stepOrderIds;
    } else {
      return [];
    }
  } catch (error) {
    console.log('error in getOrderedStepIds-->', error);
    return [];
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const secondsToTime = (e: number) => {
  const h = Math.floor(e / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, '0');

  return h + ':' + m + ':' + s;
  //return `${h}:${m}:${s}`;
};

export const convertSecondsToReadableString = (seconds: number) => {
  seconds = seconds || 0;
  seconds = Number(seconds);
  seconds = Math.abs(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const parts = [];

  if (d > 0) {
    parts.push(d + ' day' + (d > 1 ? 's' : ''));
  }

  if (h > 0) {
    parts.push(h + ' hour' + (h > 1 ? 's' : ''));
  }

  if (m > 0 && d <= 0) {
    parts.push(m + ' minute' + (m > 1 ? 's' : ''));
  }

  if (s > 0 && d <= 0 && h <= 0) {
    parts.push(s + ' second' + (s > 1 ? 's' : ''));
  }

  return parts.join(', ');
};

export const getIPFromGeoJS = async () => {
  try {
    const ipResp = await axios.get('https://get.geojs.io/v1/ip/country.json');
    return ipResp.data?.country;
  } catch (error) {
    console.log('error in getIPFromGeoJS-->', error);
    return null;
  }
};

const getIpCountryFromCloudFlare = async () => {
  try {
    const ipResp: any = await axios.get('https://cloudflare.com/cdn-cgi/trace', {
      timeout: 2000,
    });
    // console.log("CLOUD FLARE IP RESPONSE --> ", ipResp)
    const data = ipResp.data;
    const arr = data
      .trim()
      .split('\n')
      .map((e: any) => e.split('='));
    const finalData = Object.fromEntries(arr);
    return finalData.loc;
  } catch (error) {
    console.log('error in getIpCountryFromCloudFlare-->', error);
    return getIPFromGeoJS();
  }
};

export const getIPCountry = async () => {
  try {
    const ipResp: any = await axios.get('https://1.1.1.1/cdn-cgi/trace', {
      timeout: 2000,
    });
    const data = ipResp.data;
    const arr = data
      .trim()
      .split('\n')
      .map((e: any) => e.split('='));
    const finalData = Object.fromEntries(arr);
    return finalData.loc;
  } catch (error) {
    return getIpCountryFromCloudFlare();
  }
};

export const setUserSSOEntities = async (ssoId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/sso/entities', {
      params: {
        userId: ssoId,
      },
    });
    setGlobalState({
      consentedEntities: resp.payload
        .filter((a: any) => a.grantStatus === true)
        .map((x: any) => x.legalEntity),
    });
  } catch (error) {
    console.log('error in setUserSSOEntities-->', error);
    throw error;
  }
};

export const checkSessionLapseCondition = async (data: {
  session: ISession;
  isDoorOpen: boolean;
  inTime: any;
  timeToSessionEnd: number;
  timeToDoorOpen: number;
  timeToSessionStart: number;
}) => {
  try {
    const {
      inTime,
      session,
      isDoorOpen,
      timeToSessionEnd,
      timeToDoorOpen,
      timeToSessionStart,
    } = data;
    let isLapsed = false;
    if (session?.lapseCriteria) {
      const { fromIndex, lapseTime, toIndex } = session.lapseCriteria;
      const eventResp = await getEventByTargets([
        session.lapseCriteria.targetVideoElementId,
      ]);
      // console.log("EVENT RESP LASPSE ", eventResp)
      const targetEvent = (eventResp || []).find((a: any) => a.type === 'ECG_UPDATE');
      // console.log("TARGET LAPSE EVENT ", targetEvent)
      if (targetEvent) {
        const { ecg = '' } = targetEvent as IEvent;
        // console.log("TARGET REMOTE ECG ", ecg)
        const slotsCompleted = timeToDoorOpen / 60;
        // const slotsCompleted = timeToSessionStart / 60
        const numberOfZerosToAdd = slotsCompleted - ecg.length;
        const alteredECG =
          ecg + '0'.repeat(numberOfZerosToAdd > 0 ? numberOfZerosToAdd : 0);
        if (alteredECG.length < toIndex) {
          isLapsed = false;
        }
        // console.log("ALTERED ECG ", alteredECG)
        const lapsePartInECG = alteredECG.slice(fromIndex, toIndex);
        const lapseRegex = new RegExp(`0{${lapseTime}}`, 'gi');
        const isLapse = lapseRegex.test(lapsePartInECG);
        if (isLapse) {
          toast.error(
            retrieveLanguageSensitiveText(session?.meta?.lapsedText ?? []) ||
              'You have missed essential parts of the session. Please contact the support team for further steps.',
          );
        }
        isLapsed = isLapse;
      } else {
        isLapsed = true;
      }
    } else {
      isLapsed = false;
    }
    // console.log(
    //   `isDoorOpen ${isDoorOpen} timeToSessionEnd ${timeToSessionEnd} isLapsed ${isLapsed} inTime ${inTime}`,
    // );
    // console.log(
    //   'RETURNING  ',
    //   (isDoorOpen || (inTime !== undefined && !isLapsed)) && timeToSessionEnd < 0,
    // );
    return (isDoorOpen || (inTime !== undefined && !isLapsed)) && timeToSessionEnd < 0;
  } catch (error) {
    console.log('error in checkSessionLapseCondition-->', error);
    return false;
  }
};

export const getLanguageDescription = (langCode: string) => {
  try {
    const target = LANGUAGES.find((a) => a.code === langCode);
    return target?.nativeName ?? '-';
    // return `${target?.name} (${target?.nativeName})`
  } catch (error) {
    console.log('error in getLanguageDescription-->', error);
    return '';
  }
};

export const renderTableCellValue = (tableEntry: ITableEntry): string => {
  try {
    const { type, value, format: formatString } = tableEntry;
    switch (type) {
      case TABLE_ENTRY_TYPE.TEXT: {
        return value as string;
      }
      case TABLE_ENTRY_TYPE.DATE_TIME: {
        const val: Date = typeof value === 'string' ? new Date(value) : value;
        if (formatString) {
          return format(val, formatString);
        } else {
          return val.toISOString();
        }
      }
      default: {
        return '';
      }
    }
  } catch (error) {
    return 'error formatting';
  }
};

export const verifyEntitlements = (
  expectedEntitlements: string,
  userEntitlements: string[],
) => {
  try {
    if (expectedEntitlements.length === 1) {
      return true;
    } else {
      const operator = expectedEntitlements[0];
      const restWithoutOperator = expectedEntitlements.slice(1);
      const entitlements = restWithoutOperator.split(',');
      if (operator === '&') {
        return entitlements.every((a) => userEntitlements.includes(a));
      }
      if (operator === '|') {
        return entitlements.some((a) => userEntitlements.includes(a));
      }
      return false;
    }
  } catch (error) {
    console.log('error in verifyEntitlements-->', error);
  }
};

/*
function to find max consecutive occurence count of a character in a string
eg: maximumCosequtiveCount("001000","0") -> 3
maximumCosequtiveCount("0010100001000000") -> 5

*/

export const maximumCosequtiveCount = (inputString: string, character: string) => {
  let maxCount = 0;
  let currentCount = 0;

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === character) {
      currentCount++;
    } else {
      maxCount = Math.max(maxCount, currentCount);
      currentCount = 0;
    }
  }

  maxCount = Math.max(maxCount, currentCount);
  return maxCount;
};

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    '<a target="_blank" class="underline text-primary" href="$1">$1</a>',
  );
};

export const convertToWebp = (file: File, filename: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      if (file.type === 'image/webp') return resolve(file);
      const rawImage = new Image();

      rawImage.src = URL.createObjectURL(file);

      rawImage.onload = () => {
        try {
          const canvas = document.createElement('canvas');

          canvas.width = rawImage.naturalWidth;
          canvas.height = rawImage.naturalHeight;

          canvas.getContext('2d')?.drawImage(rawImage, 0, 0);

          canvas.toBlob(function (blob) {
            if (blob) {
              const myImage = new File([blob], `${filename}.webp`, {
                type: blob.type,
              });
              resolve(myImage);
            } else {
              reject('blob is null');
            }
          }, 'image/webp');
        } catch (err) {
          reject(err);
        }
      };
    } catch (err) {
      reject(err);
    }
  });
};
