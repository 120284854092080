import clsx from 'clsx';
import { useState } from 'react';

import { ENVIRONMENT_TYPE } from '@/config';
import useEventStore from '@/store/eventStore';

const EMQXStatusBar = () => {
  const [isInFulScreen, setIsInFulScreen] = useState(false);
  const { sentEvents, receivedEvents } = useEventStore();

  const toggleFullScreen = () => {
    setIsInFulScreen(!isInFulScreen);
  };

  if (ENVIRONMENT_TYPE === 'PROD' && localStorage.getItem('emqx-debug') !== 'true') {
    return null;
  }

  return (
    <div
      className={clsx(
        !isInFulScreen &&
          'fixed bottom-10 right-10 h-auto w-80 bg-gray-400 rounded hidden md:flex md:flex-col',
        isInFulScreen &&
          ' z-[10000000] overflow-hidden w-screen h-screen fixed bg-base-300 hidden md:flex md:flex-col',
      )}
    >
      <button
        id={'emqx-conn-dot'}
        className="btn btn-circle btn-outline border-2 absolute right-10 bottom-10"
        onClick={toggleFullScreen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 stroke-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      </button>

      {isInFulScreen && (
        <div className="flex flex-row w-full h-full bg-green-200">
          <div className="flex flex-col flex-[0.5] w-full bg-base-100 p-4 gap-2 border-r overflow-y-scroll">
            <article className="prose">
              <h2>Sent events</h2>
            </article>
            {sentEvents.map((event, index) => (
              <details className="collapse bg-base-200 shrink-0" key={index}>
                <summary className="collapse-title text-lg sans-medium">
                  <article className="prose prose-sm flex flex-row justify-between">
                    <h4> {event?.type}</h4>
                    <p> {event?.createdAt?.toISOString?.()}</p>
                  </article>
                </summary>
                <div className="collapse-content">
                  <pre>{JSON.stringify(event, null, 2)}</pre>
                </div>
              </details>
            ))}
          </div>
          <div className="flex flex-col flex-[0.5] w-full bg-base-100 p-4">
            <article className="prose">
              <h2>Received events</h2>
            </article>
            {receivedEvents.map((event, index) => (
              <details className="collapse bg-base-200 shrink-0" key={index}>
                <summary className="collapse-title text-lg sans-medium">
                  <article className="prose prose-sm flex flex-row justify-between">
                    <h4> {event?.type}</h4>
                    <p> {event?.createdAt?.toISOString?.()}</p>
                  </article>
                </summary>
                <div className="collapse-content">
                  <pre>{JSON.stringify(event, null, 2)}</pre>
                </div>
              </details>
            ))}
          </div>
        </div>
      )}

      {/* <button
        className="material-symbols-outlined text-xl absolute right-10 cursor-pointer"
        onClick={toggleFullScreen}
      >
        fullscreen
      </button> */}
      {/* <div className="flex flex-row items-center p-2">
        <div
          id={'emqx-conn-dot'}
          className="w-4 h-4 font-bold rounded-full animate-pulse bg-green-700"
        />
        <div className="ml-2 font-bold text-sm">EMQX</div>
        <div id={'emqx-conn-status-text'} className="ml-2 font-bold text-sm">
          Connecting...
        </div>
      </div> */}
      {/* <details className="ml-2 mb-2">
        <summary className="text-sm">Event Log</summary>
        <div
          id={'emqx-event-log'}
          className={clsx(isInFulScreen ? 'w-full h-full' : 'overflow-y-scroll max-h-40')}
        ></div>
      </details> */}
    </div>
  );
};

export default EMQXStatusBar;
