import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface GlobalStateProps {
  authToken: string | null;
  userId: string | null;
  accessToken: string | null;
  userEmail: string | null;
  anonymousEmailId: string | null;
  userPhoto: string | null;
  firstName: string | null;
  lastName: string | null;
  countryOfResidence: string | null;
  legalEntity: string | null;
  consentedEntities: string[];
}

const INITIAL_STATE = {
  authToken: null,
  userId: null,
  accessToken: null,
  userEmail: null,
  anonymousEmailId: null,
  userPhoto: null,
  firstName: null,
  lastName: null,
  countryOfResidence: null,
  legalEntity: null,
  consentedEntities: [],
};

const useUserStore = create<GlobalStateProps>()(
  devtools(
    persist(
      (set, get) => ({
        ...INITIAL_STATE,
      }),
      {
        name: 'ipd-user-base',
      },
    ),
  ),
);
export const useGlobalState = useUserStore;

export const resetGlobalState = (state = {}) => {
  try {
    useUserStore.setState({ ...INITIAL_STATE, ...state });
  } catch (error) {
    console.log('error in resetGlobalState-->', error);
  }
};

export const getAuthToken = () => {
  return useUserStore.getState().authToken;
};
export const getSSOId = () => {
  return useUserStore.getState().userId;
};

export const setGlobalState = (data: any) => {
  try {
    useUserStore.setState({
      ...useUserStore.getState(),
      ...data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getUserDetails = () => {
  return useUserStore.getState();
};

export const getAccessorObject = () => {
  const { userId, accessToken, anonymousEmailId } = useUserStore.getState();

  if (userId) {
    return { ssoId: userId };
  } else if (accessToken) {
    return { ssoId: accessToken };
  } else if (anonymousEmailId) {
    return { ssoId: anonymousEmailId };
  } else {
    throw new Error('accessor not found');
  }
};
