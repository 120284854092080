import { setProgramState } from '@/store/program';
import { axiosBridged } from '@/utils/network';

export const fetchTagsInBatch = async (batchId: string, setGlobalState = true) => {
  try {
    const resp: IAPIResp = await axiosBridged.get('/tags/list', {
      params: { batchId },
    });
    setGlobalState &&
      setProgramState({
        tags: resp.payload,
      });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchTagsInBatch ', error);
    throw error;
  }
};

export const fetchTag = async (tagId: string) => {
  try {
    const resp: IAPIResp = await axiosBridged.get(`/tags/${encodeURIComponent(tagId)}`);

    setProgramState({
      currentTag: resp.payload,
    });
    return resp.payload;
  } catch (error) {
    console.log('error in fetchTag-->', error);
    throw error;
  }
};
