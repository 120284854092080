import { ENVIRONMENT_TYPE } from '@/config';
import { getServerTime } from '@/utils/timeKeeper';

const TimeKeeperUi = () => {
  const showTimerUI = localStorage.getItem('showTimerUI');
  if (ENVIRONMENT_TYPE === 'PROD' && showTimerUI !== 'true') {
    return null;
  }
  const currentServerTime = getServerTime();
  return (
    <div className="fixed bottom-10 z-[9999] left-5  w-fit h-12 bg-green-300 rounded-md flex flex-col justify-center items-center p-2">
      <span id={'timeKeeper-text'} className={'w-auto zenDot font-bold'}>
        {currentServerTime ? currentServerTime?.toLocaleString() : 'Loading...'}
      </span>
    </div>
  );
};

export default TimeKeeperUi;
