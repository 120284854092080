import { isDesktop } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { useGlobalState } from '@/store';
import { useProgramState } from '@/store/program';
import { retrieveLanguageSensitiveText } from '@/utils';

import logo from '../../assets/images/logo.svg';
import MarqueeText from '../MarqueeText';
const Header = () => {
  const location = useLocation();
  // const { userPhoto } = useGlobalState();
  const userPhoto =
    useGlobalState((state) => state.userPhoto) || 'https://place-hold.it/100x100';
  const currentProgram = useProgramState((state) => state.currentProgram);
  const currentRegistartion = useProgramState((state) => state.currentRegistration);

  // const { currentProgram } = useProgramState();
  // const navigate = useNavigate();
  // const isDeskTop = useMediaQuery('(min-width: 768px)');
  // const isAnonymous = currentProgram?.registrationMode === REGISTRATION_MODE.ANONYMOUS;
  // const isinLC =
  //   location.pathname.toLowerCase() === '/learningcenter' ||
  //   location.pathname.toLowerCase() === '/nolc';

  // const onClickLogo = () => {
  //   try {
  //     if (currentProgram) {
  //       if (currentProgram.registrationMode === REGISTRATION_MODE.ANONYMOUS) {
  //         return;
  //       }
  //     }
  //     window.location.replace('/');
  //   } catch (error) {
  //     console.log('error in onCLickLogo-->', error);
  //   }
  // };

  // const onClickLearningCenter = useCallback(() => {
  //   navigate(isinLC ? '/' : '/learningCenter', { replace: true });
  // }, [isinLC]);

  if (location.pathname.includes('login') || location.pathname.includes('logout')) {
    return null;
  }
  return (
    <div className="sticky top-0 z-40 w-full">
      <div className="p-4 bg-base-100 navbar bg-accent">
        <div className="flex-0">
          <a className="text-xl normal-case btn btn-link" href={'/'}>
            <img
              data-testid={'logo'}
              alt={'logo'}
              src={logo}
              className="object-contain w-10 h-10 cursor-pointer md:w-12 md:h-12"
            />
          </a>
        </div>
        {currentProgram ? (
          isDesktop ? (
            <div className="serif-medium  text-lg text-center table-cell mx-auto text-text md:w-[600px] w-[240px] h-auto overflow-x-hidden">
              {retrieveLanguageSensitiveText(
                currentProgram.label,
                currentRegistartion?.streamLanguage,
              )}
            </div>
          ) : (
            <MarqueeText
              text={retrieveLanguageSensitiveText(
                currentProgram.label,
                currentRegistartion?.streamLanguage,
              )}
              textClasses={
                'serif-medium text-lg text-center table-cell md:text-xl mx-auto text-text'
              }
            />
          )
        ) : (
          <div className="mx-auto" />
        )}
        <div className="flex-none">
          <label htmlFor="my-drawer" className="drawer-button btn btn-square btn-ghost">
            <div className="avatar">
              <div className="w-8 rounded-full aspect-square ring ring-primary ring-offset-base-100 ring-offset-2">
                <img alt="dp" src={userPhoto} className="bg-cover" />
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Header;

/*
<div className="bg-base-300  sticky top-0 z-50 h-16 lg:h-20 flex flex-row items-center justify-between px-4 md:px-10 lg:px-10">
      <button onClick={onClickLogo}>
        <img
          data-testid={'logo'}
          alt={'logo'}
          src={logo}
          className="md:w-12 md:h-12 h-10 w-10 object-contain cursor-pointer"
        />
      </button>

      {currentProgram &&
        (isDeskTop ? (
          <div className="serif-medium  text-lg text-center table-cell md:text-xl  md:ml-12 text-text md:w-[600px] w-[240px] h-auto overflow-x-hidden">
            {retrieveLanguageSensitiveText(currentProgram.label)}
          </div>
        ) : (
          <MarqueeText
            text={retrieveLanguageSensitiveText(currentProgram.label)}
            textClasses={
              'serif-medium text-lg text-center table-cell md:text-xl  md:ml-12 text-text'
            }
          />
        ))}

      <div className="flex flex-row items-center justify-center">
        {!currentProgram && (
          <button onClick={onClickLearningCenter}>
            <div className="text-base sans-medium text-primary md:mr-6 mr-4 mt-0.5">
              {isinLC ? 'Home' : 'Learning Center'}
            </div>
          </button>
        )}
        {userPhoto && (
          <img
            src={`${userPhoto}#t=${new Date().getTime()}`}
            alt={'profile-pic'}
            className="hidden md:block mr-4 md:w-12 md:h-12 h-10 w-10 object-contain rounded-full"
          />
        )}
        <svg
          onClick={toggleDrawer}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="black"
          className="w-6 h-6 cursor-pointer"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </div>
    </div>

*/
