import axios from 'axios';

import { CONFIG } from '@/config';
import { getAuthToken } from '@/store';

import { hideLoadingOverlay } from '.';

export const axiosBridged = axios.create({
  baseURL: CONFIG.baseApiUrl,
  timeout: 10000,
  // withCredentials: true,
});

// Add a request interceptor
axiosBridged.interceptors.request.use(
  function (config: any) {
    // if (!(config as any)?.noLoadingUI ?? false) {
    //   showLoadingOverlay();
    // }
    // Do something before request is sent
    if (config?.headers) {
      (config.headers as any)['Authorization'] = `Bearer ${getAuthToken()}`;
    }
    return config;
  },
  function (error) {
    hideLoadingOverlay();
    // Do something with request error
    return Promise.reject(error);
  },
);

axiosBridged.interceptors.response.use(
  function (response) {
    hideLoadingOverlay();
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response?.data;
  },
  function (error) {
    hideLoadingOverlay();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // if status code is 401, redirect to login page
    if (error?.response?.status === 401) {
      // redirect to login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);
