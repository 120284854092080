import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface IEventStore {
  sentEvents: IEvent[];
  receivedEvents: IEvent[];
  addSentEvent: (event: IEvent) => void;
  addReceivedEvent: (event: IEvent) => void;
}

const INITIAL_STATE = {
  sentEvents: [],
  receivedEvents: [],
};

const useEventStore = create<IEventStore>()(
  devtools((set, get) => ({
    ...INITIAL_STATE,
    addSentEvent: (event: IEvent) => {
      set((state) => ({
        sentEvents: [...state.sentEvents, event],
      }));
    },
    addReceivedEvent: (event: IEvent) => {
      set((state) => ({
        receivedEvents: [...state.receivedEvents, event],
      }));
    },
  })),
);

export default useEventStore;
